import React, { useEffect, useState, useRef } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'; // Import GLTFLoader

// Function to create the 3D car model with randomization (we will add a GLTF model here)
const createCar = (scene, speed, seats, safety, efficiency, selectedCar, setCarModel, colorOption) => {
  // Randomization factor for car's scale (size)
  const randomFactor = Math.random() * 0.5 + 0.5;

  // Load the car model based on the user's choice
  const loader = new GLTFLoader();
  console.log("Trying to load Car:", selectedCar);
  loader.load(`/cars/${selectedCar}.glb`, (gltf) => {
    const car = gltf.scene;
    const box = new THREE.Box3().setFromObject(car);
    const center = box.getCenter(new THREE.Vector3());
    car.position.sub(center);

    // Adjust the car's scale based on speed, seats, safety, and efficiency
    const scaleFactor = randomFactor;

    // Adjust car scale based on speed (faster cars might be more compact)
    if (speed === 'slow') {
      car.scale.set(scaleFactor, scaleFactor, scaleFactor); // Slow car
    } else if (speed === 'medium') {
      car.scale.set(scaleFactor * 1.2, scaleFactor * 1.2, scaleFactor * 1.2); // Medium car
    } else if (speed === 'fast') {
      car.scale.set(scaleFactor * 1.5, scaleFactor * 1.5, scaleFactor * 1.5); // Fast car
    }

    // Adjust car scale based on seating capacity
    if (seats === '2') {
      car.scale.set(scaleFactor * 1, scaleFactor * 0.8, scaleFactor * 1); // 2 seats
    } else if (seats === '4') {
      car.scale.set(scaleFactor * 1.2, scaleFactor * 1, scaleFactor * 1.2); // 4 seats
    } else if (seats === '6') {
      car.scale.set(scaleFactor * 1.5, scaleFactor * 1.2, scaleFactor * 1.5); // 6 seats
    }

    // Adjust car scale based on safety (safer cars might be bulkier)
    if (safety === 'low') {
      car.scale.set(scaleFactor * 0.8, scaleFactor * 1, scaleFactor * 1); // Low safety, smaller body
    } else if (safety === 'medium') {
      car.scale.set(scaleFactor * 1, scaleFactor * 1, scaleFactor * 1); // Medium safety, normal body
    } else if (safety === 'high') {
      car.scale.set(scaleFactor * 1.2, scaleFactor * 1.2, scaleFactor * 1.2); // High safety, bulkier body
    }

    // Adjust car scale based on efficiency
    if (efficiency === 'electric') {
      car.scale.set(scaleFactor * 1.3, scaleFactor * 1.3, scaleFactor * 1.3); // Electric car is slightly bigger
    } else if (efficiency === 'gas') {
      car.scale.set(scaleFactor, scaleFactor, scaleFactor); // Gas car size
    } else if (efficiency === 'power') {
      car.scale.set(scaleFactor * 1.2, scaleFactor * 1.2, scaleFactor * 1.2); // Power car size
    }

    // Apply color based on user selection
    if (colorOption === 'same') {
      // Keep original color
      car.traverse((child) => {
        if (child.isMesh && child.material) {
          child.material.color.set(0xffffff); // Assuming the default model color is white
        }
      });
    } else if (colorOption === 'change') {
      // Change to a random color
      const randomColor = new THREE.Color(Math.random(), Math.random(), Math.random());
      car.traverse((child) => {
        if (child.isMesh && child.material) {
          child.material.color.set(randomColor);
        }
      });
    }

    // Add the model to the scene
    scene.add(car);

    // Store the car model in state to track it for future removal
    setCarModel(car);
  }, undefined, (error) => {
    console.error('Error loading car model:', error);
  });
};

const CarApp = () => {
  const canvasRef = useRef(null);
  const [speed, setSpeed] = useState('medium');
  const [seats, setSeats] = useState('4');
  const [safety, setSafety] = useState('medium');
  const [efficiency, setEfficiency] = useState('electric');
  const [selectedCar, setSelectedCar] = useState('sports');
  const [colorOption, setColorOption] = useState('same'); // New state for color selection
  const [carModel, setCarModel] = useState(null); // State to store car model
  const sceneRef = useRef(new THREE.Scene());
  const cameraRef = useRef(new THREE.PerspectiveCamera(100, window.innerWidth / window.innerHeight, 0.1, 1000));
  const rendererRef = useRef(null);
  const controlsRef = useRef(null);

  useEffect(() => {
    const scene = sceneRef.current;
    const camera = cameraRef.current;

    const renderer = new THREE.WebGLRenderer({ canvas: canvasRef.current });
    renderer.setSize(window.innerWidth, window.innerHeight);
    rendererRef.current = renderer;

    camera.position.set(0, 4, 12);  // Move camera further away on the Z-axis
    camera.fov = 75; // Optional: Increase FOV for wider view
    camera.updateProjectionMatrix();
    
    controlsRef.current = new OrbitControls(camera, canvasRef.current);
    controlsRef.current.enableDamping = true;
    controlsRef.current.dampingFactor = 0.25;
    controlsRef.current.enableZoom = true;

    const ambientLight = new THREE.AmbientLight(0xcccccc);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight.position.set(1, 1, 1).normalize();
    scene.add(directionalLight);

    const animate = () => {
      if (rendererRef.current) {
        requestAnimationFrame(animate);
        controlsRef.current.update();
        renderer.render(scene, camera);
      }
    };
    animate();

    return () => {
      if (rendererRef.current) rendererRef.current.dispose();
      if (controlsRef.current) controlsRef.current.dispose();
    };
  }, []);
  const handleLoadClick = () => {
    const scene = sceneRef.current;

    // Remove previous car model from the scene
    if (carModel) {
      scene.remove(carModel);
    }

    // Create the new car model based on the selected options
    createCar(scene, speed, seats, safety, efficiency, selectedCar, setCarModel, colorOption);
  };

    const handleGenerateClick = async () => {
        const scene = sceneRef.current;

        // Remove previous car model
        if (carModel) {
            scene.remove(carModel);
        }

        // Fetch the generated 3D model from the server (assuming it's generated via AI/ML)
        const response = await fetch('http://localhost:8000/generateCar', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ speed, seats, safety, efficiency })
        });

        const data = await response.json();

        if (data.modelUrl) {
            // Load the generated .glb model
            const loader = new GLTFLoader();
            loader.load(data.modelUrl, (gltf) => {
            const car = gltf.scene;
            scene.add(car);
            setCarModel(car);
            });
        } else {
            console.error('Model generation failed');
        }
    };

  const resetCar = () => {
    const scene = sceneRef.current;

    // Remove car model from the scene
    if (carModel) {
      scene.remove(carModel);
    }

    // Reset the car model state
    setCarModel(null);
  };

  const giveFeedback = (feedback) => {
    fetch('http://localhost:8000/feedback', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ feedback, carDesign: { speed, seats, safety, efficiency, selectedCar } }),
    })
    .then((response) => response.json())
    .then((data) => {
      console.log('Feedback submitted:', data);
      handleGenerateClick();
    })
    .catch((error) => {
      console.error('Error submitting feedback:', error);
    });
  };

  return (
    <div id="container">
      <div id="controls-container">
        {/* Existing controls */}
        <label htmlFor="car-selection">Car Model:</label>
        <select id="car-selection" value={selectedCar} onChange={(e) => setSelectedCar(e.target.value)}>
          <option value="car">Car</option>
          <option value="car_glb">Car glb</option>
          <option value="sports">Sports Car</option>
          <option value="sedan">Sedan</option>
          <option value="luxury_sedan">Luxury Sedan</option>
          <option value="luxury_suv">Luxury SUV</option>
          <option value="transformer">Transformers LOL</option>
          <option value="truck">Truck</option>
          <option value="motorcycle">Motorcycle</option>
          <option value="suv">SUV</option>
        </select>
        {/* New Color Dropdown */}
        <label htmlFor="color-selection">Car Color:</label>
        <select id="color-selection" value={colorOption} onChange={(e) => setColorOption(e.target.value)}>
          <option value="same">Same Color</option>
          <option value="change">Change Color</option>
        </select>
        <button onClick={handleLoadClick}>Load Car</button>
        
        <label htmlFor="speed">Speed:</label>
        <select id="speed" value={speed} onChange={(e) => setSpeed(e.target.value)}>
          <option value="slow">Slow</option>
          <option value="medium">Medium</option>
          <option value="fast">Fast</option>
        </select>

        <label htmlFor="seats">Seating Capacity:</label>
        <select id="seats" value={seats} onChange={(e) => setSeats(e.target.value)}>
          <option value="2">2 Seats</option>
          <option value="4">4 Seats</option>
          <option value="6">6 Seats</option>
        </select>

        <label htmlFor="safety">Safety Level:</label>
        <select id="safety" value={safety} onChange={(e) => setSafety(e.target.value)}>
          <option value="low">Low</option>
          <option value="medium">Medium</option>
          <option value="high">High</option>
        </select>

        <label htmlFor="efficiency">Efficiency:</label>
        <select id="efficiency" value={efficiency} onChange={(e) => setEfficiency(e.target.value)}>
          <option value="electric">Electric</option>
          <option value="gas">Gas</option>
          <option value="power">Power</option>
        </select>
        <button onClick={handleGenerateClick}>Generate Car</button>
        <button onClick={resetCar}>Reset</button>
        <button onClick={() => giveFeedback("good")}>Good</button>
        <button onClick={() => giveFeedback("bad")}>Bad</button>
      </div>

      <canvas ref={canvasRef}></canvas>
    </div>
  );
};

export default CarApp;
