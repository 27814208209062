import React from 'react';
import { useEffect } from 'react';

import CarApp from '../components/CarGen'; 
import './LandingPage.css';
import '../components/Navbar.css';

function CarCreator(){
  useEffect(() => {

    // Function to update the landing page height
    const updateCarCreatorPageHeight = () => {
      const navbar = document.querySelector('.navbar');
      const navbarHeight = navbar ? navbar.offsetHeight : 50;  // Default height if navbar isn't found
      const viewportHeight = window.innerHeight;

      // Set the landing page height dynamically
      document.querySelector('.landing-page').style.height = `${viewportHeight - navbarHeight}px`;
    };
    
    // Initial call to set height on load
    updateCarCreatorPageHeight();

    // Update the height whenever the window is resized (for responsive behavior)
    window.addEventListener('resize', updateCarCreatorPageHeight);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateCarCreatorPageHeight);
    };
  }, []);  // Empty array means this effect runs once on mount

  return (
    <div className="landing-page">
      <section className="render-section">
        <CarApp />
      </section>
      </div>
  );
}
export default CarCreator;
