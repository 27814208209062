import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import LandingPage from './pages/LandingPage';
import AboutMe from './pages/AboutMe';
import Projects from './pages/Projects';
import Mechatronics from './pages/Mechatronics';
import FPGA from './pages/fpga';
import IoTNetwork from './pages/IoTNetwork';
import CarStatsPage from './pages/carstats';
import Resume from './pages/Resume';
import Contact from './pages/Contact';
import CarCreator from './pages/CarCreator';

// const isMobile = /Mobi|Android/i.test(navigator.userAgent);

// // Apply different logic based on device
// if (isMobile) {
//   // For mobile devices
//   document.body.classList.add('mobile');
//   // Example: Adjust 3D rendering or UI elements
// } else {
//   // For desktop devices
//   document.body.classList.add('desktop');
// }

function App() {
  return (
    <Router>
      <Navbar />
      <div className="content">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/aboutme" element={<AboutMe />} />
          <Route path="/carstats" element={<CarStatsPage />} />
          <Route path="/cargen" element={<CarCreator />} />

          <Route path="/projects" element={<Projects />} />
          <Route path="/projects/mechatronics" element={<Mechatronics />} />
          <Route path="/projects/fpga" element={<FPGA />} />
          <Route path="/projects/iotnetwork" element={<IoTNetwork />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
