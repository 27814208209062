import React, { useEffect, useState, useRef, useCallback } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { loadData } from "../utils/DataLoader"; // Your data loader utility
import "./World.css";  // Import your CSS for styling

const Earth = ({ electricData, gasData }) => {
  const canvasRef = useRef(null);
  const [graphData, setGraphData] = useState("electric");
  const [populationFile, setPopulationFile] = useState(null);
  const [electricFile, setElectricFile] = useState(null);
  const [gasFile, setGasFile] = useState(null);

  const scene = useRef(new THREE.Scene()).current;
  const camera = useRef(new THREE.PerspectiveCamera(50, window.innerWidth / window.innerHeight, 0.1, 10)).current;
  const renderer = useRef(null);
  const controls = useRef(null);

  const barsRef = useRef([]);  // Store references to bars to handle cleanup

  // Color generation based on value
  const getColorForValue = (value) => {
    const color = new THREE.Color();
    const minValue = 0; // Set your minimum value here
    const maxValue = 1000000; // Set your maximum value here
    const scaledValue = Math.min(Math.max(value, minValue), maxValue) / maxValue; // Ensure the value is clamped between 0 and 1
    color.setHSL(scaledValue, 0.8, 0.5); // Use HSL for coloring
    return color;
  };

  const updateBars = useCallback((data) => {
    console.log('Before Cleanup - Scene Children:', scene.children);
  
    // Clear previous dots from the scene by removing from `barsRef`
    barsRef.current.forEach((dot) => {
      scene.remove(dot);
    });
    barsRef.current = [];  // Reset the reference array after cleanup
  
    // Scale factors for correct positioning
    const scale = 1.015;  // Radius scale for dots
  
    // Arrays to store positions and colors for the points
    const positions = [];
    const colors = [];
  
    // Create a circle texture
    const texture = createCircleTexture();  // This function will generate a circle texture
  
    // Loop through the data and create colored dots for each point
    data.forEach(([latitude, longitude, value]) => {
      if (value === -9999) return;  // Skip invalid data points
  
      // Create color based on value (use a function like getColorForValue)
      const color = getColorForValue(value);
  
      // Convert latitude/longitude to spherical coordinates
      const phi = (latitude + 90) * (Math.PI / 180); // Latitude to phi (in radians)
      const theta = (longitude + 180) * (Math.PI / 180); // Longitude to theta (in radians)
  
      // Add position (convert spherical coordinates to Cartesian)
      positions.push(
        scale * Math.sin(phi) * Math.cos(theta),
        scale * Math.cos(phi),  // Height from the center of the sphere (Y axis)
        scale * Math.sin(phi) * Math.sin(theta)
      );
  
      // Add color to the colors array (RGB format)
      colors.push(color.r, color.g, color.b);
    });
  
    // Create a BufferGeometry for the dots
    const geometry = new THREE.BufferGeometry();
  
    // Set positions and colors for the geometry
    geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
    geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));
  
    // Create PointsMaterial with the circle texture
    const material = new THREE.PointsMaterial({
      size: 0.05,  // Size of the dots (radius of the circle)
      vertexColors: true,  // Allow the color per vertex
      map: texture,  // Apply circle texture to the points
      transparent: true,  // Make sure the texture is transparent (circle on transparent background)
      alphaTest: 0.5,  // Ensure transparency is respected
    });
  
    // Create a Points object (this is the 2D dot representation on the globe)
    const points = new THREE.Points(geometry, material);
    points.rotation.z = Math.PI / 1;  // Rotate to align the map properly
  
    // Add the points to the scene
    scene.add(points);
    barsRef.current.push(points);  // Store the reference for cleanup
  
    console.log('After Cleanup - Scene Children:', scene.children);
  
    // Update the legend after bars are added
  }, [scene]);
  
  // Function to create a simple circle texture
  const createCircleTexture = () => {
    const size = 64;  // Texture size (64x64)
    const canvas = document.createElement('canvas');
    canvas.width = size;
    canvas.height = size;
  
    const ctx = canvas.getContext('2d');
    const radius = size / 2;
    const gradient = ctx.createRadialGradient(radius, radius, 0, radius, radius, radius);
    
    // Gradient for smooth circle
    gradient.addColorStop(0, 'white');
    gradient.addColorStop(1, 'rgba(255, 255, 255, 0)'); // Transparent edge
    
    // Draw a circle on the canvas
    ctx.beginPath();
    ctx.arc(radius, radius, radius, 0, 2 * Math.PI);
    ctx.fillStyle = gradient;
    ctx.fill();
    ctx.closePath();
  
    // Return the texture
    const texture = new THREE.CanvasTexture(canvas);
    texture.needsUpdate = true;
    return texture;
  };


  // Load the data (electric and gas) and set it to state
  useEffect(() => {
    if (canvasRef.current) {
      renderer.current = new THREE.WebGLRenderer({ canvas: canvasRef.current });
      renderer.current.setSize(window.innerWidth, window.innerHeight);
      camera.position.set(4, 3, 5); // Move camera back for a better view

      controls.current = new OrbitControls(camera, canvasRef.current);
      controls.current.enableDamping = true;
      controls.current.enablePan = false;

      controls.current.minDistance = 1;
      controls.current.maxDistance = 8;

      controls.current.minPolarAngle = -Infinity; // Allow the camera to move from the North Pole to the South Pole
      controls.current.maxPolarAngle = Infinity;  // Allow 180-degree rotation (full vertical spin)
  
      // Allow horizontal rotation without restriction (optional, but useful for full control)
      controls.current.minAzimuthAngle = -Infinity; // Allow horizontal rotation to 180 degrees on both sides
      controls.current.maxAzimuthAngle = 0;  // Allow horizontal rotation to 180 degrees on both sides
  
      controls.current.target.set(0, 0, 0); // Ensure the target is always at the center of the Earth

      controls.current.update();

      scene.background = new THREE.Color("black");

      // Add light sources
      const light = new THREE.AmbientLight(0x404040); // Ambient light
      scene.add(light);

      const directionalLight = new THREE.DirectionalLight(0xffffff, 1); // Directional light
      directionalLight.position.set(1, 1, 1).normalize();
      scene.add(directionalLight);

      // Create the Earth Sphere (globe)
      const earthGeometry = new THREE.SphereGeometry(1, 64, 64);  // Use higher segments for better UV resolution
      const earthMaterial = new THREE.MeshStandardMaterial({
        color: 0xaaaaaa,  // Default color of the Earth
        emissive: 0x111111,  // Darker shading
        roughness: 0.7,  // Earth-like roughness
        metalness: 0.3,  // Earth-like metalness
        side: THREE.DoubleSide,  // Ensure texture is visible from both sides
      });

      const earth = new THREE.Mesh(earthGeometry, earthMaterial);
      scene.add(earth);

      // Load world map texture and display it on a sphere
      const loader = new THREE.TextureLoader();
      loader.load("/world.jpg", (texture) => {
        // Check if the texture is loading correctly
        if (!texture) {
          console.error("Failed to load texture");
          return;
        }

        // Ensure that the texture is equirectangular
        texture.wrapS = THREE.RepeatWrapping;
        texture.wrapT = THREE.RepeatWrapping;
        texture.repeat.set(1, 1); // Make sure the texture wraps properly

        // Create a MeshBasicMaterial for the world map texture
        const earthMapMaterial = new THREE.MeshBasicMaterial({
          map: texture,
          side: THREE.DoubleSide,
          emissive: 0x111111,
          emissiveIntensity: 0.2,
        });

        // Create a new mesh for the world map
        const earthMap = new THREE.Mesh(earthGeometry, earthMapMaterial);
        
        // Slightly enlarge the map and position it just outside the Earth surface
        earthMap.scale.set(1.01, 1.01, 1.01);  // Slightly larger to sit outside the Earth sphere
 
        scene.add(earthMap);
        //earthMap.rotation.z = -Math.PI / 1;  // Rotate to align the map properly

      });

      // Load electric and gas data
      loadData("/data/population.json").then((populationFileData) =>{
        setPopulationFile(populationFileData);
        loadData("/data/electric2.json").then((electricFileData) => {
          setElectricFile(electricFileData);
          loadData("/data/gasData.json").then((gasFileData) => {
            setGasFile(gasFileData);
          });
        });
      });

      // Animation loop
      const animate = () => {
        requestAnimationFrame(animate);
        controls.current.update();
        renderer.current.render(scene, camera);
      };
      animate();
    }
  }, [electricData, gasData, camera, scene]);

  // Resize event listener
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      renderer.current.setSize(width, height);
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Update bars whenever the data changes
  useEffect(() => {
    if (populationFile && electricFile && gasFile) {
      updateBars(graphData === "electric" ? electricFile : (graphData === "gas" ? gasFile : populationFile));
    }
  }, [electricFile, gasFile, populationFile, graphData, updateBars]);

  // Handle the button clicks to switch between electric and gas
  const handleButtonClick = (type) => {
    setGraphData(type);
  };

  return (
    <div className="earth-container">
      <div className="button-container">
        <button
          onClick={() => handleButtonClick("electric")}
          className={graphData === "electric" ? "active" : ""}
        >
          Electric
        </button>
        <button
          onClick={() => handleButtonClick("gas")}
          className={graphData === "gas" ? "active" : ""}
        >
          Gas
        </button>
        <button
          onClick={() => handleButtonClick("population")}
          className={graphData === "population" ? "active" : ""}
        >
          Population
        </button>
      </div>
      <canvas ref={canvasRef} />
    </div>
  );
};

export default Earth;
